const moment = require("moment");

const getToken = () => {
    return localStorage.getItem('token') || null;
}

const getUserDetail = () => {
    return JSON.parse(localStorage.getItem('user_detail'));
}

const getUserId = () => {
    return (localStorage.getItem('user_detail')) ? JSON.parse(localStorage.getItem('user_detail'))['user_id'] : 0;
}

const getUserType = () => {
    if (localStorage.getItem('user_detail')) {
        return JSON.parse(localStorage.getItem('user_detail'))['u_type'];
    } else {
        return 0;
    }
}

const removeUserSession = () => {
    localStorage.removeItem('token');
    localStorage.removeItem('user_detail');
}

const setUserSession = (token, user) => {
    localStorage.setItem('token', token);
    localStorage.setItem('user_detail', JSON.stringify(user));
}

const is_login = () => {
    if (localStorage.getItem('token')) {
        return true;
    } else {
        return false;
    }
}

const amountFormat = (amount) => {
    return parseFloat(amount).toFixed(2);
}

const img_view = (img, width = '', height = '', auto = true) => {
    return process.env.REACT_APP_IMG_URL + 'uploads/' + img
    if (auto) {
        return process.env.REACT_APP_IMG_URL + 'img?img=' + img + '&w=' + width + '&h=' + height
    } else {
        return process.env.REACT_APP_IMG_URL + 'uploads/' + img
    }
}
const Date_format = (date) => {
    var date = new Date(date),
        mnth = ("0" + (date.getMonth() + 1)).slice(-2),
        day = ("0" + date.getDate()).slice(-2);
    return  [date.getFullYear(), mnth, day].join("-");
}


const IsModemAccess = (modem_name,type) => {
    var modem = JSON.parse(localStorage.getItem('user_detail'))?.['roleAssigned'];
    if(modem && modem[modem_name] && modem[modem_name].module[type] === 1){
        return true;
    }else{
        return false;
    }
}

const uct_local = (data_time,format ="DD/MM/YYYY HH:mm:ss") => {
    if(data_time){
        return moment.utc(data_time).local().format(format);
    }else{
        return '';
    }
}

const get_order_status_color = (status) => {
    status = (status)? status.toLowerCase() : '';
    if(status == 'blockchainpending'){
        return 'bg-blockchainpending';
    }else if(status == 'pending'){
        return 'bg-pending';
    } else if(status == 'on-hold'){
        return 'bg-on-hold';
    }else if(status == 'failed'){
        return 'bg-failed';
    }else if(status == 'cancelled'){
        return 'bg-cancelled';
    }else if(status == 'processing'){
        return 'bg-processing';
    }else if(status == 'in-process'){
        return 'bg-in-process';
    }else if(status == 'completed'){
        return 'bg-completed';
    }else if(status == 'refunded'){
        return 'bg-refunded';
    }else if(status == 'in-review'){
        return 'bg-in-review';
    }else {
        return 'bg-warning';
    }
}

const get_smm_order_status_color = (status) => {
    status = status.toLowerCase();

    if(status == 'On-Hold'){
        return 'bg-on-hold';
    }if(status == 'pending'){
        return 'bg-pending';
    }else if(status == 'failed' || status == 'cancelled' || status == 'canceled' || status == 'partial'){
        return 'bg-cancelled';
    }else if(status == 'processing'){
        return 'bg-processing';
    }else if(status == 'in progress'){
        return 'bg-in-process';
    }else if(status == 'completed'){
        return 'bg-success';
    }else if(status == 'refunded'){
        return 'bg-secondary';
    }else {
        return 'bg-primary';
    }
}
export {get_smm_order_status_color, get_order_status_color, uct_local, IsModemAccess, Date_format,img_view, amountFormat, getUserType, getToken, removeUserSession, setUserSession, is_login, getUserDetail, getUserId };