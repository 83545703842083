
import React, { useRef, useState } from 'react';
import SimpleReactValidator from 'simple-react-validator';

import { uct_local, get_order_status_color, get_smm_order_status_color, IsModemAccess } from '../../helpers/Helper';
import { post } from '../../helpers/api_helper';
import { POST_GET_SMM_FORM_OPTION, POST_GET_ORDER_DETAIL, POST_SMM_PLACE_ORDER, POST_UPDATE_ORDER_STATUS, POST_ORDER_LOCK_UNLOCK, POST_EMAIl_SEND } from '../../helpers/url_helper';
import { toast } from 'react-toastify';
import useMounted from '../../hooks/useMounted';
import Loader from '../../components/Loader';

import "datatables.net-dt/js/dataTables.dataTables";
import "datatables.net-dt/css/dataTables.dataTables.css";
import { Link, useParams } from 'react-router-dom';

import Select from 'react-select';
import $ from 'jquery';


const UserRoles = (props) => {
    const [isLoader, setLoader] = useState(0);


    const [ButtonDisabled, SetButtonDisabled] = useState(false);
    const [ShowOrderForm, SetShowOrderForm] = useState(false);
    const { id } = useParams()

    const simpleValidator = useRef(new SimpleReactValidator());
    const emailValidator = useRef(new SimpleReactValidator());

    const [, forceUpdate] = useState();

    const [OrderDetail, SetOrderDetail] = useState({});
    const [smmPanelsList, SetSmmPanelsList] = useState([]);
    const [smmServiceList, SetSmmServiceList] = useState([]);
    const [smmServiceTypeList, SetSmmServiceTypeList] = useState([]);
    const [emailTemplateList, SetEmailTemplateList] = useState([]);
    const [TypeArray, SetTypeArray] = useState([]);
    const [OrderFormInputs, setOrderFormInputs] = useState({ orderId: 0, orderStatus: '', orderNotes: '', noteType: 'internal' });
    const [EmailForm, setEmailForm] = useState({ orderId: 0, email: '', emailTemplateId: '', subject: '', message: '' });

    const [form_inputs, setInputs] = useState({
        orderId: '',
        productId: '',
        productName: '',
        smmPanelId: '',
        smmPanelServiceId: '',
        smmServicesInfo: '',
        link: '',
        quantity: '',
        comments: '',
        usernames: '',
        hashtags: '',
        media: '',
        answer_number: '',
        groups: '',
        min: '',
        max: '',
        delay: '',
        expiry: '',
        orderInfo: '',
        updateOrderStatus : false
    });

    useMounted(() => {
        async function get_detaile() {
            await getOrderDetail();

            setLoader(1);
        }
        get_detaile();
    }, [])


    const getOrderDetail = async () => {
        SetOrderDetail({})
        var res_data = await post(POST_GET_ORDER_DETAIL, { orderId: id });
        if (res_data.status) {
            SetOrderDetail(res_data.data.order_info);
            OrderFormInputs.orderId = id;
            OrderFormInputs.orderStatus = res_data.data.order_info.orderStatus.toLowerCase();

            setOrderFormInputs({ ...OrderFormInputs })
        } else {
            toast.error(res_data.message);
        }
    }

    const getSmmFormOption = async () => {
        if (smmPanelsList.length > 0) {
            return;
        }
        SetButtonDisabled(true);
        var res_data = await post(POST_GET_SMM_FORM_OPTION);
        if (res_data.status) {
            SetSmmPanelsList(res_data.data.smm_panels_list);
            SetSmmServiceTypeList(res_data.data.smm_panels_services_types_list);
            SetEmailTemplateList(res_data.data.email_template_list);
        } else {
            toast.error(res_data.message);
        }
        SetButtonDisabled(false);
    }

    // input text change handler
    const handleInputChange = (event) => {
        if (event.target.name == 'smmPanelId') {
            SetSmmServiceList(event.target.value.serviceList);
            form_inputs[event.target.name] = event.target.value.smmPanelId;
            form_inputs['smmPanelServiceId'] = '';
            form_inputs['smmServicesInfo'] = '';
            setInputs({ ...form_inputs });
        } else if (event.target.name == 'smmPanelServiceId') {
            form_inputs[event.target.name] = event.target.value.service;
            form_inputs['smmServicesInfo'] = event.target.value;

            SetTypeArray([]);
            for (var ServiceType of smmServiceTypeList) {
                if (ServiceType.type == event.target.value.type) {
                    SetTypeArray([...ServiceType.parameters]);
                    break;
                }
            }
            setInputs({ ...form_inputs });
        } else if(event.target.name == 'updateOrderStatus'){
            form_inputs[event.target.name] = (event.target.checked)? true : false
            setInputs({ ...form_inputs });
        } else {
            form_inputs[event.target.name] = event.target.value;
            setInputs({ ...form_inputs });
        }
    }

    const setOrdervalue = async (data) => {
        console.log(OrderDetail);
        form_inputs.orderId = id;
        form_inputs.productId = data.productId;
        form_inputs.productName = data.productName;
        form_inputs.quantity = data.product_detail[0].value;
        form_inputs.link = data.product_detail[data.product_detail.length - 1].value;
        form_inputs.usernames = data.product_detail[data.product_detail.length - 1].value;
        form_inputs.orderInfo = data.product_detail;
        form_inputs.updateOrderStatus = (OrderDetail.orderStatus == 'Processing')? true : false
        setInputs({ ...form_inputs });

        await getSmmFormOption();

        SetShowOrderForm(true);
    }
    // form submit event
    const handleSubmit = async (event) => {
        event.preventDefault();
        const formValid = simpleValidator.current.allValid()
        if (!formValid) {
            simpleValidator.current.showMessages(true);
            forceUpdate(1);
        } else {
            SetButtonDisabled(true);
            var res_data = await post(POST_SMM_PLACE_ORDER, form_inputs);
            if (res_data.status) {
                toast(res_data.message);
                await getOrderDetail();
                SetShowOrderForm(false);

            } else {
                toast.error(res_data.message);
            }
            SetButtonDisabled(false);
        }
    }


    const handleSubmitOrderStatus = async (event) => {
        event.preventDefault();
        SetButtonDisabled(true);
        var res_data = await post(POST_UPDATE_ORDER_STATUS, OrderFormInputs);
        if (res_data.status) {
            toast(res_data.message);

            OrderFormInputs.orderNotes = '';
            setOrderFormInputs({ ...OrderFormInputs });
            await getOrderDetail();

            $('.modal-close').click();
        } else {
            toast.error(res_data.message);
        }
        SetButtonDisabled(false);
    }

    // input text change handler
    const handleInputChangeOrderStatus = (event) => {
        OrderFormInputs[event.target.name] = event.target.value;
        setOrderFormInputs({ ...OrderFormInputs });
    }

    // input text change handler
    const handleInputChangeEmail = (event) => {
        if (event.target.name == 'emailTemplateId') {
            EmailForm[event.target.name] = event.target.value;

            const result = emailTemplateList.find(({ emailTemplateId }) => emailTemplateId == event.target.value);
            console.log(result)
            EmailForm.subject = (result) ? result.subject : '';
            EmailForm.message = (result) ? result.message : '';
            setEmailForm({ ...EmailForm });
        } else {
            EmailForm[event.target.name] = event.target.value;
            setEmailForm({ ...EmailForm });
        }
    }

    const handleSubmitEmailSend = async (event) => {
        event.preventDefault();
        const formValid = emailValidator.current.allValid()
        if (!formValid) {
            emailValidator.current.showMessages(true);
            forceUpdate(1);
        } else {
            SetButtonDisabled(true);

            var res_data = await post(POST_EMAIl_SEND, EmailForm);
            if (res_data.status) {
                toast(res_data.message);
                $('.modal-close').click();
            } else {
                toast.error(res_data.message);
            }
            SetButtonDisabled(false);
        }
    }

    const lockUnlock = async () => {
        if (!IsModemAccess('web_order', 'edit')) {
            toast.error('Does not permission this module');
            return;
        }
        SetButtonDisabled(true);
        var res_data = await post(POST_ORDER_LOCK_UNLOCK, { orderId: OrderDetail.orderId, orderType: OrderDetail.orderType });
        if (res_data.status) {
            await getOrderDetail();
            toast(res_data.message);
        } else {
            toast.error(res_data.message);
        }
        SetButtonDisabled(false);
    }
    if (!isLoader) {
        return (<Loader />);
    }
    return (
        <>
            <div className='row'>
                <div className="col-12 col-sm-12 col-md-12 col-lg-8 mb-4">
                    <div className="card">
                        <div className="card-body">
                            <div className='row mb-3'>
                                <div className='col-sm-6'>
                                    <h5 className='m-0'>Order Detaile</h5>
                                </div>
                                <div className='col-sm-6 text-right'>
                                    {IsModemAccess('web_order', 'edit') &&
                                        <button type='button' onClick={() => { setEmailForm({ orderId: OrderDetail.orderId, email: OrderDetail.email, emailTemplateId: '', subject: '', message: '' }) }} data-bs-toggle="modal" data-bs-target="#EmailModal" className='btn btn-sm btn-primary mr-2'>Send Email</button>
                                    }
                                    &nbsp; &nbsp; &nbsp;
                                    {ButtonDisabled && <i className="fa fa-spinner me-2 fa-spin"></i>}
                                    {(!ButtonDisabled && OrderDetail.orderType == 'Auto') && <i onClick={lockUnlock} className="text-danger bx bx-lock align-middle" style={{ 'fontSize': '25px' }}></i>}
                                    {(!ButtonDisabled && OrderDetail.orderType == 'Manual') && <i onClick={lockUnlock} className="text-success bx bx-lock-open align-middle" style={{ 'fontSize': '25px' }}></i>}
                                </div>
                            </div>
                            <hr className='mb-3' />
                            <div className='row mb-3'>
                                <div className='col-sm-4'>
                                    <p className='mb-2'><b>Web Code : </b><br /> {OrderDetail.code}</p>
                                    <p className='mb-2'><b>User Name : </b><br /> {OrderDetail.firstName} {OrderDetail.lastName}</p>
                                    <p className='mb-2'><b>User Email : </b> <br />{OrderDetail.email}</p>
                                </div>
                                <div className='col-sm-4'>
                                    <p className='mb-2'><b>Order No : </b><br /> {OrderDetail.orderNumber}</p>
                                    <p className='mb-2'><b>Order Date : </b><br /> {uct_local(OrderDetail.dateCreated)}</p>
                                    <p className='mb-2'><b>Order Status : </b> <br />
                                        <span className={`badge text-capitalize ${get_order_status_color(OrderDetail.orderStatus)}`}>{OrderDetail.orderStatus}</span>
                                        {(IsModemAccess('web_order', 'edit') && OrderDetail.orderType == 'Manual') && <button className="btn btn-sm text-primary mr-2" data-bs-toggle="modal" data-bs-target="#EditModal" ><i className="bx bx-edit"></i></button>}
                                    </p>
                                </div>
                                <div className='col-sm-4'>
                                    <p className='mb-2'><b>Payment Method : </b><br /> {OrderDetail.paymentMethodTitle}</p>
                                    <p className='mb-2'><b>Payment Method Code : </b><br /> {OrderDetail.paymentMethod}</p>
                                    <p className='mb-2'><b>Transaction Id : </b><br /> {OrderDetail.transactionId}</p>
                                    <p className='mb-2'><b>Order Amount : </b><br /> {OrderDetail.currency} {OrderDetail.total}</p>
                                </div>
                            </div>

                            <div className='row mb-3'>
                                <div className='col-sm-12'>
                                    <div className='table-responsive'>
                                        <table className='table table-border table-sm'>
                                            <thead>
                                                <tr>
                                                    <th width="50">Sr.</th>
                                                    <th>Product Name</th>
                                                    <th>Detailes</th>
                                                    <th width="200">Action</th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                {OrderDetail.orderDetail && OrderDetail.orderDetail.map((productDetail, index) => (
                                                    <tr key={index}>
                                                        <td>{index + 1}</td>
                                                        <td>{productDetail.productName}</td>
                                                        <td>
                                                            {productDetail.product_detail && productDetail.product_detail.map((value, index) => (
                                                                <p className='mb-0' key={index}><b>{value.name} : </b> {value.value}</p>
                                                            ))}
                                                        </td>
                                                        <td>
                                                            {((OrderDetail.orderStatus == 'Processing' || OrderDetail.orderStatus == 'In-Process' || OrderDetail.orderStatus == 'In-Review') && IsModemAccess('web_order', 'edit') && OrderDetail.orderType == 'Manual') &&
                                                                <button type='button' onClick={setOrdervalue.bind(this, productDetail)} className='btn btn-sm btn-primary'>Place Order</button>
                                                            }
                                                        </td>
                                                    </tr>
                                                ))}
                                            </tbody>
                                        </table>
                                    </div>
                                </div>
                            </div>

                            <div className='row mb-3'>
                                <div className='col-sm-12'>
                                    <p className='mb-2'><b>User Order Note : </b> </p>
                                    <p className='mb-2'>{OrderDetail.note}</p>
                                </div>
                            </div>
                            <hr />

                        </div>
                    </div>
                </div>

                {ShowOrderForm && <div className="col-12 col-sm-12 col-md-12 col-lg-4 mb-4">
                    <div className="card">
                        <div className="card-body">
                            <div className='row'>
                                <div className='col-sm-12'>
                                    <h5>SMM Order Form</h5>
                                </div>
                                <hr className='mb-3' />
                                <div className='col-sm-12'>
                                    <form className="av-invalid" onSubmit={handleSubmit}>
                                        <div className='mb-3'>
                                            <label className="form-label text-capitalize">Select SMM Portal *</label>
                                            <Select
                                                getOptionLabel={option => option.name}
                                                getOptionValue={option => option.smmPanelId}
                                                defaultValue={smmPanelsList.find((a) => { return a.smmPanelId == form_inputs.smmPanelId })}
                                                onChange={(v) => { handleInputChange({ target: { name: "smmPanelId", value: v } }) }}
                                                options={smmPanelsList}
                                            />
                                            {simpleValidator.current.message('smm panel', form_inputs.smmPanelId, 'required')}
                                        </div>
                                        <div className='mb-3'>
                                            <label className="form-label text-capitalize">Select SMM Service *</label>
                                            <Select
                                                getOptionLabel={option => option.title}
                                                getOptionValue={option => option.service}
                                                defaultValue={form_inputs.smmServicesInfo}
                                                onChange={(v) => { handleInputChange({ target: { name: "smmPanelServiceId", value: v } }) }}
                                                options={smmServiceList}
                                            />
                                            {simpleValidator.current.message('service', form_inputs.smmPanelServiceId, 'required')}
                                        </div>
                                        {TypeArray.includes('link') && <div className='mb-3'>
                                            <label className="form-label text-capitalize">Link *</label>
                                            <input name="link" value={form_inputs.link} onChange={handleInputChange} className="form-control" placeholder="Enter Link" type="text" />
                                            {simpleValidator.current.message('link', form_inputs.link, 'required')}
                                        </div>}
                                        {TypeArray.includes('quantity') && <div className='mb-3'>
                                            <label className="form-label text-capitalize">Quantity *</label>
                                            <input name="quantity" value={form_inputs.quantity} onChange={handleInputChange} className="form-control" placeholder="Enter Quantity" type="text" />
                                            {simpleValidator.current.message('quantity', form_inputs.quantity, 'required|numeric')}
                                        </div>}
                                        {TypeArray.includes('comments') && <div className='mb-3'>
                                            <label className="form-label text-capitalize">Comments *</label>
                                            <input name="comments" value={form_inputs.comments} onChange={handleInputChange} className="form-control" placeholder="Enter Comments" type="text" />
                                            <small className='ml-5'>Comments list separated by \r\n or \n</small>
                                            {simpleValidator.current.message('comments', form_inputs.comments, 'required')}
                                        </div>}
                                        {TypeArray.includes('usernames') && <div className='mb-3'>
                                            <label className="form-label text-capitalize">Usernames *</label>
                                            <input name="usernames" value={form_inputs.usernames} onChange={handleInputChange} className="form-control" placeholder="Enter Usernames" type="text" />
                                            <small className='ml-5'>Usernames list separated by \r\n or \n</small>
                                            {simpleValidator.current.message('usernames', form_inputs.usernames, 'required')}
                                        </div>}
                                        {TypeArray.includes('hashtags') && <div className='mb-3'>
                                            <label className="form-label text-capitalize">Hashtags *</label>
                                            <input name="hashtags" value={form_inputs.hashtags} onChange={handleInputChange} className="form-control" placeholder="Enter Hashtags" type="text" />
                                            <small className='ml-5'>Hashtags list separated by \r\n or \n</small>
                                            {simpleValidator.current.message('hashtags', form_inputs.hashtags, 'required')}
                                        </div>}
                                        {TypeArray.includes('media') && <div className='mb-3'>
                                            <label className="form-label text-capitalize">Media *</label>
                                            <input name="media" value={form_inputs.media} onChange={handleInputChange} className="form-control" placeholder="Enter Media" type="text" />
                                            <small className='ml-5'>Media URL to scrape likers from</small>
                                            {simpleValidator.current.message('media', form_inputs.media, 'required')}
                                        </div>}
                                        {TypeArray.includes('answer_number') && <div className='mb-3'>
                                            <label className="form-label text-capitalize">Answer Number *</label>
                                            <input name="answer_number" value={form_inputs.answer_number} onChange={handleInputChange} className="form-control" placeholder="Enter Answer Number" type="text" />
                                            <small className='ml-5'>Answer number of the poll</small>
                                            {simpleValidator.current.message('answer number', form_inputs.answer_number, 'required')}
                                        </div>}
                                        {TypeArray.includes('groups') && <div className='mb-3'>
                                            <label className="form-label text-capitalize">Groups *</label>
                                            <input name="groups" value={form_inputs.groups} onChange={handleInputChange} className="form-control" placeholder="Enter Groups" type="text" />
                                            <small className='ml-5'>Groups list separated by \r\n or \n</small>
                                            {simpleValidator.current.message('groups', form_inputs.groups, 'required')}
                                        </div>}
                                        {TypeArray.includes('min') && <div className='mb-3'>
                                            <label className="form-label text-capitalize">Min *</label>
                                            <input name="min" value={form_inputs.min} onChange={handleInputChange} className="form-control" placeholder="Enter Min" type="text" />
                                            {simpleValidator.current.message('min', form_inputs.min, 'required|numeric')}
                                        </div>}
                                        {TypeArray.includes('max') && <div className='mb-3'>
                                            <label className="form-label text-capitalize">Max *</label>
                                            <input name="max" value={form_inputs.max} onChange={handleInputChange} className="form-control" placeholder="Enter Max" type="text" />
                                            {simpleValidator.current.message('max', form_inputs.max, 'required|numeric')}
                                        </div>}
                                        {TypeArray.includes('delay') && <div className='mb-3'>
                                            <label className="form-label text-capitalize">Delay *</label>
                                            <select name='delay' value={form_inputs.max} onChange={handleInputChange} className="form-control">
                                                <option value="">Select Delay</option>
                                                <option value="0">0</option>
                                                <option value="5">5</option>
                                                <option value="10">10</option>
                                                <option value="15">15</option>
                                                <option value="30">30</option>
                                                <option value="60">60</option>
                                                <option value="90">90</option>
                                                <option value="120">120</option>
                                                <option value="150">150</option>
                                                <option value="180">180</option>
                                                <option value="210">210</option>
                                                <option value="240">240</option>
                                                <option value="270">270</option>
                                                <option value="300">300</option>
                                                <option value="360">360</option>
                                                <option value="420">420</option>
                                                <option value="480">480</option>
                                                <option value="540">540</option>
                                                <option value="600">600</option>
                                            </select>
                                            {simpleValidator.current.message('delay', form_inputs.delay, 'required')}
                                        </div>}
                                        {TypeArray.includes('expiry') && <div className='mb-3'>
                                            <label className="form-label text-capitalize">Expiry </label>
                                            <input name="expiry" value={form_inputs.expiry} onChange={handleInputChange} className="form-control" placeholder="Enter Expiry" type="text" />
                                            <small>Expiry date. Format d/m/Y</small>
                                        </div>}
                                        {OrderDetail.orderStatus == 'Processing' && <div className='mb-3'>
                                            <div className="form-check form-switch mb-2">
                                                <label className="form-check-label" htmlFor="flexSwitchCheckDefault">Automate Update Web Order Status  Processing to In Process</label>
                                                <input className="form-check-input" defaultChecked={form_inputs.updateOrderStatus} name='updateOrderStatus' onChange={handleInputChange} type="checkbox" id="flexSwitchCheckDefault" />
                                            </div>
                                        </div>}
                                        <div className='text-center'>
                                            <button disabled={ButtonDisabled} className="btn btn-primary pt-2 pb-2">
                                                {ButtonDisabled && <i className="fa fa-spinner me-2 fa-spin"></i>} Place Order
                                            </button>
                                            <button type='button' onClick={() => { SetShowOrderForm(false) }} className="btn btn-danger ms-3"> Cancel</button>
                                        </div>
                                    </form>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>}
            </div>

            <div className='row mb-3'>
                <div className='col-sm-12'>
                    <div className="card">
                        <div className="card-body">
                            <h5>SMM Order list</h5>
                            <div className='table-responsive'>
                                <table className='table table-striped table-sm'>
                                    <thead>
                                        <tr>
                                            {/* <th>Sr.</th> */}
                                            <th width="120px">Panel Code</th>
                                            <th width="100px">Order No</th>
                                            <th width="170px">Date</th>
                                            <th>Service</th>
                                            <th>Type</th>
                                            <th>Link</th>
                                            <th>Quantity</th>
                                            <th>Remains</th>
                                            <th>Status</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {OrderDetail.smm_order_list && OrderDetail.smm_order_list.map((value, key) => {
                                            return (
                                                <tr key={key}>
                                                    {/* <td>{key + 1}</td> */}
                                                    <td>{value.code}</td>
                                                    <td>{value.smmOrderNumber}</td>
                                                    <td>{uct_local(value.createdAt)}</td>
                                                    <td>{value.smmServiceName}</td>
                                                    <td>{value.smmServiceType}</td>
                                                    <td><Link to={value.link} target="_blank">{value.link}</Link></td>
                                                    <td>{value.quantity}</td>
                                                    <td>{value.remainsQuantity}</td>
                                                    <td>
                                                        <span className={`badge text-capitalize ${get_smm_order_status_color(value.smmOrderStatus)}`}>{value.smmOrderStatus}</span>
                                                    </td>
                                                </tr>
                                            )
                                        })}
                                    </tbody>
                                </table>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div className='row'>
                <div className='col-sm-12'>
                    <div class="nav-align-top mb-4">
                        <ul class="nav nav-tabs" role="tablist">
                            <li class="nav-item">
                                <button
                                    type="button"
                                    class="nav-link active"
                                    role="tab"
                                    data-bs-toggle="tab"
                                    data-bs-target="#navs-top-home"
                                    aria-controls="navs-top-home"
                                    aria-selected="true">
                                    User Activity Log
                                </button>
                            </li>
                            <li class="nav-item">
                                <button
                                    type="button"
                                    class="nav-link"
                                    role="tab"
                                    data-bs-toggle="tab"
                                    data-bs-target="#navs-top-profile"
                                    aria-controls="navs-top-profile"
                                    aria-selected="false">
                                    Web Order Note
                                </button>
                            </li>
                        </ul>
                        <div class="tab-content">
                            <div class="tab-pane fade show active" id="navs-top-home" role="tabpanel">
                                <div className='table-responsive'>
                                    <table className='table table-sm'>
                                        <tbody>
                                            {OrderDetail.orderLog && OrderDetail.orderLog.map((data, key) => {
                                                return (<tr key={key}>
                                                    <td width="190px">{uct_local(data.createdAt)}</td>
                                                    <td style={{ 'minWidth': '180px' }}>{data.userName}</td>
                                                    <td style={{ 'minWidth': '220px' }}>{data.email}</td>
                                                    <td width="100px">{data.noteType}</td>
                                                    <td>{data.message}</td>
                                                </tr>)
                                            })}
                                        </tbody>
                                    </table>
                                </div>
                                {OrderDetail.orderLog && OrderDetail.orderLog.length <= 0 &&
                                    <p className='text-center'>No any data</p>
                                }
                            </div>
                            <div class="tab-pane fade" id="navs-top-profile" role="tabpanel">
                                <div className='table-responsive'>
                                    <table className='table table-sm'>
                                        <tbody>
                                            {OrderDetail.notes_list && OrderDetail.notes_list.map((note, key) => {
                                                return (<tr key={key}>
                                                    <td style={{ 'minWidth': '180px' }}>{uct_local(note.date_created)}</td>
                                                    <td width="100px">{(note.customer_note) ? 'Customer' : 'Internal'}</td>
                                                    <td>{note.note}</td>
                                                </tr>)
                                            })}
                                        </tbody>
                                    </table>
                                </div>
                                {OrderDetail.notes_list && OrderDetail.notes_list.length <= 0 &&
                                    <p className='text-center'>No any notes</p>
                                }
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div className="modal fade" id="EditModal" tabIndex="-1" role="dialog" aria-labelledby="exampleModalLabel" aria-hidden="true">
                <div className="modal-dialog modal-md" role="document">
                    <div className="modal-content">
                        <div className="modal-header">
                            <h5 className="modal-title" id="exampleModalLabel">Update Order Status</h5>
                            <button type="button" className="btn btn-sm close modal-close" data-bs-dismiss="modal" aria-label="Close">
                                <i className="bx bx-x"></i>
                            </button>
                        </div>
                        <hr className='mb-0' />
                        <div className="modal-body">
                            <div className='row'>
                                <div className='col-sm-12'>
                                    <form className="av-invalid" onSubmit={handleSubmitOrderStatus}>
                                        <div className='mb-3'>
                                            <label className="form-label text-capitalize">Select Order Status </label>
                                            <select name='orderStatus' value={OrderFormInputs.orderStatus} onChange={handleInputChangeOrderStatus} className="form-control" >
                                                <option value="pending">Pending</option>
                                                <option value="processing">Processing</option>
                                                <option value="in-process">In Process</option>
                                                <option value="in-review">In Review</option>
                                                <option value="on-hold">On-Hold</option>
                                                <option value="completed">Completed</option>
                                                <option value="cancelled">Cancelled</option>
                                                <option value="refunded">Refunded</option>
                                                <option value="failed">Failed</option>
                                            </select>
                                        </div>
                                        <div className='mb-3'>
                                            <label>Note Type</label>
                                            <select name='noteType' value={OrderFormInputs.noteType} onChange={handleInputChangeOrderStatus} className="form-control" >
                                                <option value="customer">Customer</option>
                                                <option value="internal">Internal</option>
                                            </select>
                                        </div>
                                        <div className='mb-3'>
                                            <label className="form-label text-capitalize">Order Notes </label>
                                            <textarea name='orderNotes' value={OrderFormInputs.orderNotes} onChange={handleInputChangeOrderStatus} rows={4} className="form-control"></textarea>
                                        </div>
                                        <div className='text-center pt-4'>
                                            <button disabled={ButtonDisabled} className="btn btn-primary pt-2 pb-2"> {ButtonDisabled && <i className="fa fa-spinner me-2 fa-spin"></i>} Update </button>
                                            <button type='button' data-bs-dismiss="modal" aria-label="Close" className="btn btn-danger ms-3"> Cancel</button>
                                        </div>
                                    </form>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div className="modal fade" id="EmailModal" tabIndex="-1" role="dialog" aria-labelledby="exampleModalLabel" aria-hidden="true">
                <div className="modal-dialog modal-lg" role="document">
                    <div className="modal-content">
                        <div className="modal-header">
                            <h5 className="modal-title" id="exampleModalLabel">Send Email</h5>
                            <button type="button" className="btn btn-sm close modal-close" data-bs-dismiss="modal" aria-label="Close">
                                <i className="bx bx-x"></i>
                            </button>
                        </div>
                        <hr className='mb-0' />
                        <div className="modal-body">
                            <div className='row'>
                                <div className='col-sm-12'>
                                    <form className="av-invalid" onSubmit={handleSubmitEmailSend}>
                                        <div className='mb-3'>
                                            <label>To </label>
                                            <input type='text' name='email' className='form-control' value={EmailForm.email} disabled />
                                        </div>
                                        <div className='mb-3'>
                                            <label className="form-label text-capitalize">Select Email Template </label>
                                            <select name='emailTemplateId' value={EmailForm.emailTemplateId} onChange={handleInputChangeEmail} className="form-control" >
                                                <option value="">Select Email Template</option>
                                                {emailTemplateList && emailTemplateList.map((value, key) => {
                                                    return (<option key={key} value={value.emailTemplateId}>{value.name}</option>)
                                                })}
                                            </select>
                                        </div>
                                        <div className='mb-3'>
                                            <label>Subject *</label>
                                            <input type='text' name='subject' className='form-control' onChange={handleInputChangeEmail} value={EmailForm.subject} />
                                            {emailValidator.current.message('subject', EmailForm.subject, 'required')}
                                        </div>
                                        <div className='mb-3'>
                                            <label className="form-label text-capitalize">Message * </label>
                                            <textarea name='message' value={EmailForm.message} onChange={handleInputChangeEmail} rows={6} className="form-control"></textarea>
                                            {emailValidator.current.message('message', EmailForm.message, 'required')}
                                        </div>
                                        <div className='text-center pt-4'>
                                            <button disabled={ButtonDisabled} className="btn btn-primary pt-2 pb-2"> {ButtonDisabled && <i className="fa fa-spinner me-2 fa-spin"></i>} Send </button>
                                            <button type='button' data-bs-dismiss="modal" aria-label="Close" className="btn btn-danger ms-3"> Cancel</button>
                                        </div>
                                    </form>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
}

export default UserRoles;