import React from 'react';

import avatars from './../assets/img/avatars/1.png';
import { Link, useNavigate } from 'react-router-dom';
import { removeUserSession, getUserDetail } from './../helpers/Helper';

function Header(props) {
    const navigate = useNavigate();

    const handleLangChange = () => {
        props.onChangeHandleMenu();            
    }
    const logout = () => {
        removeUserSession();
        navigate("/");
    }

    return (
        <>
            <nav className="layout-navbar container-fluid navbar navbar-expand-xl navbar-detached align-items-center bg-navbar-theme"
                id="layout-navbar">
                <div className="layout-menu-toggle navbar-nav align-items-xl-center me-3 me-xl-0 d-xl-none">
                    <Link className="nav-item nav-link px-0 me-xl-4" onClick={handleLangChange} to="javascript:void(0)">
                        <i className="bx bx-menu bx-sm"></i>
                    </Link>
                </div>

                <div className="navbar-nav-right d-flex align-items-center" id="navbar-collapse">
                    <ul className="navbar-nav flex-row align-items-center ms-auto">
                        <li className='nav-item lh-1 me-3'>
                            <p className='mb-0 text-capitalize'>{getUserDetail()?.name}</p>
                        </li>
                        <li className="nav-item navbar-dropdown dropdown-user dropdown">
                            <Link className="nav-link dropdown-toggle hide-arrow" to="javascript:void(0);"
                                data-bs-toggle="dropdown">
                                <div className="avatar avatar-online">
                                    <img src={avatars} alt="avatars" className="w-px-40 h-auto rounded-circle" />
                                </div>
                            </Link>
                            <ul className="dropdown-menu dropdown-menu-end">
                                <li>
                                    <Link className="dropdown-item" to="/change-password">
                                        <span className="d-flex align-items-center align-middle">
                                            <i className="flex-shrink-0 bx bx-key me-2"></i>
                                            <span className="flex-grow-1 align-middle ms-1">Change Password</span>
                                        </span>
                                    </Link>
                                </li>
                                <li>
                                    <Link onClick={logout} className="dropdown-item" href="javascript:void(0);">
                                        <i className="bx bx-power-off me-2"></i>
                                        <span className="align-middle">Log Out</span>
                                    </Link>
                                </li>
                            </ul>
                        </li>
                    </ul>
                </div>
            </nav>
        </>
    );
}

export default Header;